.prg-text-hero {
  margin: var(--doublePadding) 0;
  padding: var(--doublePadding);
}

.prg-text-hero-container {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  gap: var(--doublePadding);
  flex-direction: column;
}

.prg-text-hero-container h2 {
  margin: 0;
  padding: 0 15%;
}

.prg-text-hero-container h2:not(.subheading) {
  font-family: var(--secondaryFont);
  color: var(--brandPrimaryColor);
  font-weight: 200;
}

.prg-text-hero-container h2.subheading {
  font-weight: 500;
}

.prg-text-hero-container p {
  width: 50%;
  margin: 0 auto;
  font-size: var(--prgBody);
  font-weight: 400;
}

/* Mobile */
@media screen and (max-width: 860px) {
  .prg-text-hero-container p {
    width: 100%;
  }
}

/* Mobile landscape */
@media screen and (max-width: 860px) and (orientation: landscape) {
  .prg-text-hero {
    margin: 0;
  }
}
