.prg-cta-row {
  background-color: var(--brandPrimaryColor);
  padding: var(--doublePadding);
  margin: var(--doublePadding) 0;
}

.prg-cta-row.style-secondary {
  background-color: var(--brandSecondaryColor);
}

.prg-cta-row-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  padding: 0 var(--doublePadding);
  gap: var(--doublePadding);
  align-items: center;
}

.prg-cta-row-heading {
  font-size: clamp(3.75rem, 3vw + 1rem, 4rem);
  font-family: var(--promoFont);
  color: var(--brandSecondaryColor);
  text-transform: uppercase;
  text-align: center;
  flex: initial;
  width: 80%;
}

.prg-cta-row.style-secondary .prg-cta-row-heading {
  color: var(--brandTertiaryColor);
}

.prg-cta-row-content button {
  display: inline-block;
  flex: initial;
  min-width: 13.2rem;
  white-space: nowrap;
  text-align: center;
  background-color: var(--brandSecondaryColor);
  border-color: var(--brandSecondaryColor);
  color: var(--brandPrimaryColor);
}

.prg-cta-row.style-secondary .prg-cta-row-content button {
  background-color: var(--brandPrimaryColor);
  color: var(--brandSecondaryColor);
}

@media screen and (max-width: 860px) {
  .prg-cta-row-content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .prg-cta-row-heading {
    width: 100%;
  }
}
