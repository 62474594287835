.prg-order-prompt-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(1, 1, 1, 0.5);
  z-index: 9999999;
}

.prg-order-prompt {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: var(--pageColor);
  width: 50vw;
  transform: translate(-50%, -50%);
  border-radius: 3px;
  padding: var(--doublePadding);
  display: flex;
  flex-direction: column;
}

.prg-order-prompt h3 {
  margin: 0 0 var(--quadPadding);
  text-align: center;
}

.prg-order-prompt h5 {
  margin: 0 0 var(--quarterPadding);
}

.prg-order-prompt h4 {
  color: var(--brandPrimaryColor);
  margin: var(--doublePadding) 0 var(--standardPadding);
}

.prg-order-prompt h4 span {
  color: var(--redColor);
}

.prg-order-prompt p {
  margin: var(--quarterPadding) 0;
}

.prg-order-prompt a {
  text-decoration: underline;
  color: var(--brandPrimaryColor);
}

.prg-order-prompt-actions {
  display: flex;
  gap: var(--doublePadding);
  justify-content: flex-end;
  margin: var(--quadPadding) 0 0;
}

.prg-order-prompt-close-btn {
  margin: 0 0 0 auto;
  opacity: 0.8;
}

.prg-order-prompt-close-btn svg path {
  fill: var(--textColor);
}

.prg-order-prompt .prg-dropdown-component {
  border: 1px solid transparent;
  padding: 0;
  padding: var(--halfPadding) 0 var(--quarterPadding);
}

.prg-order-prompt .prg-dropdown-component:not(.disabled) {
  border-color: var(--borderColor);
  border-radius: 6px;
  padding: var(--halfPadding) var(--quarterPadding) var(--quarterPadding);
}

.prg-order-prompt .prg-dropdown-component.disabled button {
  opacity: 1;
}

.prg-order-prompt .prg-dropdown-component.disabled button svg {
  display: none;
}

.prg-order-prompt .prg-dropdown-component button {
  text-transform: none;
  font-family: var(--primaryFont);
  font-size: 1.15rem;
  line-height: 1.15rem;
}

.prg-order-prompt .prg-dropdown-component-expanded {
  border-radius: 6px;
  border: 1px solid var(--borderColor);
}

.prg-order-prompt .prg-dropdown-component-expanded li {
  font-size: 1.15rem;
  font-family: var(--primaryFont);
}

/* Mobile */
@media screen and (max-width: 860px) and (orientation: portrait) {
  .prg-order-prompt {
    width: calc(100vw - 2 * var(--doublePadding));
  }

  .prg-order-prompt h3 {
    font-size: 1.5rem;
    margin: var(--standardPadding) 0;
    text-align: center;
  }

  .prg-order-prompt h5 {
    font-size: 1.25rem;
    text-align: center;
    margin: 0 0 var(--standardPadding);
  }

  .prg-order-prompt .prg-dropdown-component button {
    font-size: 1.1rem;
  }

  .prg-order-prompt .prg-dropdown-component-expanded {
    max-height: 40vh;
    gap: var(--standardPadding);
    padding: var(--standardPadding);
  }

  .prg-order-prompt .prg-dropdown-component-expanded li {
    font-size: 1.1rem;
    text-align: center;
  }
}

@media screen and (max-width: 900px) and (orientation: landscape) {
  .prg-order-prompt {
    width: calc(100vw - 2 * var(--doublePadding));
  }

  .prg-order-prompt h3 {
    font-size: 1.5rem;
    margin: var(--standardPadding) 0;
  }

  .prg-order-prompt h5 {
    font-size: 1.25rem;
    margin: 0 0 var(--standardPadding);
  }

  .prg-order-prompt .prg-dropdown-component button {
    font-size: 1.1rem;
    white-space: wrap;
  }

  .prg-order-prompt .prg-dropdown-component-expanded {
    max-height: 40vh;
    gap: var(--standardPadding);
    padding: var(--standardPadding);
  }

  .prg-order-prompt .prg-dropdown-component-expanded li {
    font-size: 1.1rem;
  }
}
