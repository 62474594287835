.prg-promo-page {
  background-color: var(--brandSecondaryColor);
  color: var(--brandPrimaryColor);
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 2rem;
}

.prg-promo-page h2 {
  font-family: var(--secondaryFontSemiBold);
  font-size: var(--prgH1);
  text-transform: uppercase;
  font-weight: 600;
}

.prg-promo-restaurant-logo {
  width: 15vmax;
  height: 15vmax;
}

.prg-promo-restaurant-logo svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.prg-promo-content {
  margin: 0 auto;
  max-width: 800px;
  text-align: center;
}

.promo-desc {
  margin: 2rem auto;
  font-family: var(--primaryFont);
}

.promo-code-container {
  background-color: var(--textColor);
  margin: 2rem auto;
  padding: 1rem;
}

.promo-code-content {
  border: 2px solid var(--brandPrimaryColor);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.promo-code-content h1 {
  font-family: var(--promoFont);
  font-size: var(--prgH1);
}

.promo-code-content h4 {
  margin: 0;
  font-size: var(--prgH3);
}

.promo-code-content img {
  width: 20%;
  margin: 1rem auto;
}

.promo-code-content h4 span {
  font-weight: 600;
  text-transform: uppercase;
  font-style: italic;
}

.prg-promo-content .prg-primary-btn {
  display: inline-block;
}

/* Mobile */
@media screen and (max-width: 900px) and (orientation: portrait) {
  .promo-code-content img {
    width: 70%;
  }
}

@media screen and (max-width: 900px) and (orientation: landscape) {
  .promo-code-content img {
    width: 30%;
  }
}
