.prg-person-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(1, 1, 1, 0.2);
  z-index: 888888;
}

.prg-person-modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75vw;
  height: 80vh;
  background-color: var(--brandPrimaryColor);
  padding: var(--doublePadding);
  color: var(--brandSecondaryColor);
  max-width: 1200px;
}

.prg-person-modal-header {
  display: flex;
  margin: 0 0 var(--standardPadding);
}

.prg-person-modal-header button {
  margin: 0 0 0 auto;
}

.prg-person-modal-flex {
  display: flex;
  gap: var(--doublePadding);
  height: calc(100% - var(--doublePadding) - var(--standardPadding));
}

.prg-person-modal-media {
  width: 45%;
  flex: initial;
}

.prg-person-modal-media img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.prg-person-modal-meta {
  width: 55%;
  flex: initial;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.prg-person-modal-meta.full-width {
  width: 100%;
}

.prg-person-modal-meta h1 {
  font-family: var(--secondaryFont);
  line-height: var(--prgH1);
  margin: -0.5rem 0 0;
}

.prg-person-modal-meta h6 {
  font-family: var(--primaryFontLight);
  font-weight: 300;
  font-size: var(--prgBody);
  margin: 0;
}

.prg-person-modal-meta-bio {
  overflow-y: auto;
  max-height: 55vh;
  margin: auto 0 0;
  font-size: var(--prgBody);
  font-family: var(--primaryFontLight);
  font-weight: 300;
}

.prg-person-modal-meta-bio span {
  display: block;
  margin: 0 0 var(--doublePadding);
}

.prg-person-modal-meta-bio span:last-of-type {
  margin: 0;
}

@media screen and (max-width: 860px) and (orientation: portrait) {
  .prg-person-modal-content {
    width: calc(100% - 2 * var(--doublePadding));
    height: 90vh;
  }

  .prg-person-modal-flex {
    flex-direction: column;
  }

  .prg-person-modal-media {
    width: 100%;
    height: 30vh;
  }

  .prg-person-modal-meta {
    width: 100%;
    height: 60vh;
    overflow-y: auto;
    display: block;
  }

  .prg-person-modal-meta.full-width {
    height: 90vh;
  }

  .prg-person-modal-meta-bio {
    overflow: hidden;
    max-height: none;
    margin: 0;
  }

  .prg-person-modal-meta h1 {
    font-size: var(--prgH2);
    line-height: var(--prgH2);
    padding: 0.25rem 0;
  }

  .prg-person-modal-meta h6 {
    margin: 0 0 var(--doublePadding);
  }
}

@media screen and (max-width: 860px) and (orientation: landscape) {
  .prg-person-modal-content {
    width: calc(100% - 2 * var(--doublePadding));
    height: 90vh;
  }

  .prg-person-modal-meta {
    height: calc(70vh - var(--doublePadding));
    overflow-y: auto;
    display: block;
  }

  .prg-person-modal-meta-bio {
    overflow: hidden;
    max-height: none;
    margin: 0;
  }

  .prg-person-modal-meta h1 {
    font-size: var(--prgH2);
    margin: -1.25rem 0 0;
  }

  .prg-person-modal-meta h6 {
    margin: 0 0 var(--doublePadding);
  }
}
