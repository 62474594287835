.prg-cta-button-group {
  margin: var(--doublePadding) 0;
  padding: 0 var(--doublePadding);
}

.prg-cta-button-group.disable-margins {
  margin: 0;
}

.prg-cta-button-group.disable-margins .prg-cta-button-group-btns {
  margin: 0;
}

.prg-cta-button-group-container {
  margin: 0 auto;
  max-width: 1200px;
  text-align: center;
  position: relative;
}

.prg-cta-button-group-container h2 {
  text-transform: uppercase;
}

.prg-cta-button-group-container p {
  font-size: var(--prgSubhead);
  width: 37%;
  margin: var(--doublePadding) auto;
}

.prg-cta-button-group-container p.subheading {
  font-weight: 600;
}

.prg-cta-button-group-btns {
  display: flex;
  gap: var(--doublePadding);
  margin: var(--doublePadding) 0 var(--quadPadding);
  align-items: center;
  justify-content: center;
}

.prg-cta-button-group-btns.vertical {
  flex-direction: column;
}

.prg-cta-button-group-btns .prg-text-btn {
  margin: 0 var(--standardPadding);
}

.prg-cta-button-group-svg {
  position: absolute;
  bottom: 45%;
  right: 45%;
  height: 110%;
  transform: translate(160%, 65%);
}

.prg-cta-button-group-svg svg {
  height: 100%;
  width: auto;
}

.prg-block-container .prg-cta-button-group-svg {
  bottom: 40%;
  right: 40%;
}

/* Mobile */
@media screen and (max-width: 860px) {
  .prg-cta-button-group-btns {
    flex-direction: column;
  }

  .prg-cta-button-group-container p {
    width: 100%;
  }

  .prg-cta-button-group-svg {
    display: none;
  }
}
