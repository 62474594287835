.prg-footer {
  background-color: var(--brandPrimaryColor);
  margin: 0;
  padding: calc(1.5 * var(--doublePadding));
}

.prg-footer-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: var(--doublePadding);
}

.prg-footer-container-item {
  position: relative;
}

.prg-footer-container .prg-footer-logo {
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.4s ease;
}

.prg-footer-container .prg-footer-logo:hover {
  opacity: 0.9;
}

.prg-footer-container .prg-footer-logo svg {
  height: 4.4375rem;
  width: auto;
}

.prg-footer-container-social,
.prg-footer-container-cta {
  display: flex;
  flex-direction: column;
  gap: var(--standardPadding);
  align-items: center;
  height: 100%;
}

.prg-footer-container-cta h2 {
  margin: 0;
  padding: 0;
  color: var(--brandSecondaryColor);
  font-family: var(--secondaryFont);
  font-weight: 300;
}

.prg-footer-container-social h2 {
  margin: 0 0 var(--quarterPadding);
  padding: 0;
  color: var(--brandSecondaryColor);
  font-size: var(--prgBody);
  font-weight: 300;
}

.prg-footer-container-social ul {
  display: flex;
  gap: var(--standardPadding);
  justify-content: center;
  align-items: center;
  margin: auto 0 var(--quarterPadding);
}

.prg-footer-container-social ul li svg {
  height: 1.2rem;
  width: auto;
}

.prg-footer-container-social ul li a {
  display: inline-block;
  transition: all 0.4s ease;
}

.prg-footer-container-social ul li a:hover {
  transform: translateY(-1px);
}

.prg-footer-container-item .prg-primary-btn {
  background-color: var(--brandSecondaryColor);
  color: var(--brandPrimaryColor);
  display: inline-block;
  border: 3px solid var(--brandSecondaryColor);
}

.prg-footer-container-item .prg-primary-btn.outline {
  background-color: transparent;
  color: var(--brandSecondaryColor);
  border: 3px solid var(--brandSecondaryColor);
}

.prg-footer-container-item .prg-primary-btn.outline:hover {
  border: 3px solid var(--brandSecondaryColor);
}

/* Mobile */
@media screen and (max-width: 860px) {
  .prg-footer-container {
    display: flex;
    gap: calc(2 * var(--doublePadding));
    flex-direction: column;
  }

  .prg-footer-container .prg-footer-logo {
    position: static;
    transform: none;
    display: block;
    text-align: center;
  }

  .prg-footer-container .prg-footer-logo svg {
    height: 3rem;
    display: block;
    margin: 0 auto;
  }

  .prg-footer-container-social ul li svg {
    height: 1rem;
  }

  .prg-footer-container-social ul {
    gap: calc(1.5 * var(--standardPadding));
  }
}
