.prg-svg-image-block {
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 var(--doublePadding);
}

.prg-svg-image-block svg {
  display: block;
  margin: 0 auto;
}

.prg-svg-image-block:not(.use-natural-width) svg {
  width: 100%;
  height: auto;
}

.prg-svg-image-block.use-natural-width svg {
  max-width: 100%;
}

/* Mobile landscape */
@media screen and (max-width: 860px) and (orientation: landscape) {
  .prg-svg-image-block {
    padding: 0 var(--quadPadding);
  }
}
