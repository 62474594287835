.prg-press-grid {
  margin: var(--quadPadding) 0;
  padding: 0 var(--doublePadding);
}

.prg-press-grid-content {
  margin: 0 auto;
  max-width: 1200px;
}

.prg-press-grid-items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--doublePadding);
}

.prg-press-grid-item {
  display: flex;
  flex-direction: column;
  gap: var(--standardPadding);
  align-items: flex-start;
}

.prg-press-grid-item img {
  width: 100%;
  display: block;
  height: 27rem;
  object-fit: cover;
  margin: 0;
  padding: 0;
}

.prg-press-grid-item h3 {
  margin: 0;
  padding: 0;
  color: var(--brandPrimaryColor);
  font-family: var(--secondaryFont);
}

.prg-press-grid-item .prg-primary-btn {
  margin: 0;
  padding: 0;
  color: var(--brandPrimaryColor);
}

/* Mobile */
@media screen and (max-width: 860px) {
  .prg-press-grid {
    margin: var(--doublePadding) 0;
  }

  .prg-press-grid-item img {
    height: 25vmax;
  }
}

@media screen and (max-width: 860px) and (orientation: landscape) {
  .prg-press-grid-items {
    grid-template-columns: repeat(
      auto-fit,
      minmax(min(100%/2, max(64px, 100%/4)), 1fr)
    );
  }
}

@media screen and (max-width: 860px) and (orientation: portrait) {
  .prg-press-grid-items {
    --grid-layout-gap: var(--doublePadding);
    --grid-column-count: 2;
    --grid-item--min-width: 240px;

    /**
     * Calculated values.
     */
    --gap-count: calc(var(--grid-column-count) - 1);
    --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
    --grid-item--max-width: calc(
      (100% - var(--total-gap-width)) / var(--grid-column-count)
    );

    /* Grid */
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr)
    );
    grid-gap: var(--grid-layout-gap);
    margin: var(--standardPadding) 0;
  }
}
