.prg-product-detail {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 999999;
}

.prg-product-detail-content {
  background-color: var(--pageColor);
  width: 85%;
  height: 70vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: var(--doublePadding) calc(2 * var(--doublePadding))
    calc(2 * var(--doublePadding));
  max-width: 76.5rem;
}

.prg-product-detail-content-inner {
  padding: calc(2 * var(--doublePadding)) 0 0;
  display: flex;
  height: 56vh;
  gap: calc(2 * var(--doublePadding));
}

.prg-product-detail-left {
  width: 50%;
  flex: initial;
  position: relative;
}

.prg-product-detail-left button:hover {
  opacity: 1;
}

.prg-product-detail-pagination-indicators {
  position: absolute;
  bottom: 2rem;
  left: 25%;
  width: 50%;
  display: flex;
  gap: var(--standardPadding);
  justify-content: center;
}

.prg-product-detail-pagination-indicators button {
  height: 1rem;
  width: 1rem;
  border-radius: 100%;
  background-color: var(--textColor);
}

.prg-product-detail-pagination-indicators button:hover,
.prg-product-detail-pagination-indicators button.selected {
  background-color: var(--brandPrimaryColor);
}

.prg-product-detail-pagination-btn {
  margin: 0;
  padding: var(--quarterPadding);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--pageColor);
  line-height: 0;
  border: 2px solid var(--brandPrimaryColor);
}

.prg-product-detail-pagination-btn svg {
  height: 1.5rem;
  width: auto;
}

.prg-product-detail-pagination-btn svg path {
  transition: all 0.3s ease;
  fill: var(--textColor);
}

.prg-product-detail-pagination-left-btn {
  left: 1rem;
}

.prg-product-detail-pagination-left-btn svg {
  transform: rotate(-90deg);
}

.prg-product-detail-pagination-right-btn {
  right: 1rem;
}

.prg-product-detail-pagination-right-btn svg {
  transform: rotate(90deg);
}

.prg-product-detail-pagination-btn:hover svg path {
  fill: var(--brandPrimaryColor);
}

.prg-product-detail-left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

.prg-product-detail-right {
  width: 50%;
  flex: initial;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--standardPadding);
}

.prg-product-detail-right h2 {
  width: 100%;
  font-size: var(--prgH3);
  font-family: var(--secondaryFontSemiBold);
  font-weight: 500;
  text-transform: uppercase;
  text-align: left;
  margin: 0;
}

.prg-product-detail-right h5 {
  width: 100%;
  font-size: var(--prgH4);
  font-family: var(--secondaryFont);
  font-weight: 400;
  text-align: left;
  margin: 0;
}

.prg-product-detail-right p {
  font-size: var(--prgBody);
  margin: auto 0;
  width: 100%;
  text-align: left;
}

.prg-product-detail-variants {
  margin: 0 0 auto;
}

.prg-product-detail-variants select {
  border: none;
  appearance: none;
  font-family: inherit;
  padding: var(--quarterPadding);
  background-color: transparent;
  border-bottom: 1px solid var(--blueColor);
  color: var(--blueColor);
  text-transform: uppercase;
  display: block;
  width: 100%;
  outline: none;
}

.prg-product-detail-right button {
  align-self: flex-start;
}

.prg-product-detail-close-btn {
  margin: 0 auto 0 0;
}

.prg-product-detail-close-btn svg path {
  fill: var(--textColor);
}

/* Mobile */
@media screen and (max-width: 860px) and (orientation: portrait) {
  .prg-product-detail-content {
    padding: var(--doublePadding);
  }

  .prg-product-detail-content-inner {
    flex-direction: column;
    overflow-y: auto;
    padding: var(--doublePadding) 0 0 0;
    height: 60vh;
    gap: var(--doublePadding);
  }

  .prg-product-detail-left,
  .prg-product-detail-right {
    width: 100%;
  }

  .prg-product-detail-right {
    margin: 0 0 var(--doublePadding);
  }

  .prg-product-detail-right button {
    align-self: center;
  }

  .prg-primary-btn:not(.outline) {
    overflow: visible;
  }
}

/* Mobile - landscape */
@media screen and (max-width: 860px) and (orientation: landscape) {
  .prg-product-detail-content {
    padding: var(--doublePadding);
    height: 80vh;
  }

  .prg-product-detail-content-inner {
    overflow-y: auto;
    padding: var(--doublePadding);
    height: 60vh;
    gap: var(--doublePadding);
  }

  .prg-product-detail-left {
    width: 30%;
    height: 10rem;
  }

  .prg-product-detail-right {
    width: 70%;
  }

  .prg-product-detail-right {
    margin: 0 0 var(--doublePadding);
  }

  .prg-product-detail-right button {
    align-self: center;
  }

  .prg-primary-btn:not(.outline) {
    overflow: visible;
  }
}
