.prg-image-block {
  width: 100%;
}

.prg-image-block img {
  width: 100%;
  display: block;
}

/* Mobile */
@media screen and (max-width: 860px) {
  .prg-image-block {
    padding: 0 var(--doublePadding);
  }

  .prg-image-block img {
    max-height: 40vh;
    object-fit: cover;
  }
}

/* Mobile landscape */
@media screen and (max-width: 860px) and (orientation: landscape) {
  .prg-image-block {
    padding: var(--doublePadding);
  }

  .prg-image-block img {
    max-height: 90vh;
  }
}
