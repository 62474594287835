.prg-shop-items-grid {
  margin: var(--doublePadding) 0 calc(1.5 * var(--quadPadding));
  padding: 0 var(--doublePadding);
}

.prg-shop-items-grid-container {
  margin: 0 auto;
  max-width: 1200px;
}

.prg-shop-section {
  --grid-layout-gap: var(--doublePadding);
  --grid-column-count: 4;
  --grid-item--min-width: 150px;

  /**
   * Calculated values.
   */
  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item--max-width: calc(
    (100% - var(--total-gap-width)) / var(--grid-column-count)
  );

  /* Grid */
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr)
  );
  grid-gap: var(--grid-layout-gap);
  margin: var(--standardPadding) 0;
}

.prg-shop-item {
  display: flex;
  gap: var(--standardPadding);
  flex-direction: column;
  opacity: 0;
  transform: translateY(100px) scale(0.95);
}

.prg-shop-item:hover {
  cursor: pointer;
  color: var(--brandSecondaryColor);
}

.prg-shop-item:hover img {
  box-shadow: 3px 4px 2px rgba(1, 1, 1, 0.2);
}

.prg-shop-item-image {
  width: 100%;
  height: 0;
  padding: 0 0 90%;
  position: relative;
}

.prg-shop-item-image img {
  width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transition: all 0.3s ease;
}

.prg-shop-item-meta {
  display: flex;
}

.prg-shop-item-meta h5 {
  width: 80%;
  flex: initial;
  text-align: left;
  font-size: var(--prgBody);
  font-family: var(--secondaryFontSemiBold);
  font-weight: 500;
  text-transform: uppercase;
}

.prg-shop-item-meta h6 {
  flex: initial;
  font-size: var(--prgBody);
  font-family: var(--secondaryFont);
  font-weight: 400;
  margin: 0 0 0 auto;
  text-align: right;
}

.prg-shop-sort {
  position: relative;
  display: flex;
}

.prg-sort-btn {
  margin: 0 0 0 auto;
  border: 2px solid var(--brandPrimaryColor);
  display: block;
  line-height: 0;
  padding: var(--quarterPadding);
  transition: all 0.3s ease;
}

.prg-sort-btn svg {
  height: 1.5rem;
  width: auto;
}

.prg-sort-btn svg path {
  stroke: var(--textColor);
  fill: var(--textColor);
}

.prg-sort-btn:hover {
  box-shadow: 3px 4px 2px rgba(1, 1, 1, 0.2);
}

.prg-sort-options {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 333;
  box-shadow: 3px 4px 2px rgba(1, 1, 1, 0.2);
  font-size: var(--prgBody);
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.prg-sort-options-container {
  display: flex;
  flex-direction: column;
  gap: var(--quarterPadding);
  padding: var(--halfPadding);
}

.prg-sort-options-container li {
  display: flex;
  align-items: center;
  gap: var(--halfPadding);
  font-size: 1rem;
}

.prg-sort-options-container li svg {
  height: 1rem;
  width: auto;
}

.prg-sort-options-container li span {
  width: 0.7rem;
  height: 0.7rem;
  border: 2px solid var(--brandPrimaryColor);
  display: inline-block;
}

.prg-sort-options-container li:not(.selected):hover {
  cursor: pointer;
  opacity: 0.8;
}

.prg-sort-options-container li.selected {
  color: var(--brandPrimaryColor);
}

.prg-sort-options-container li.selected span {
  background-color: var(--brandPrimaryColor);
}

@media screen and (max-width: 860px) {
  .prg-shop-pagination-btn svg {
    height: 1.5rem;
  }

  .prg-shop-item {
    gap: var(--quarterPadding);
  }

  .prg-shop-item-meta {
    flex-direction: column;
    align-items: center;
  }

  .prg-shop-item-meta h5,
  .prg-shop-item-meta h6 {
    margin: 0;
    text-align: center;
  }
}
