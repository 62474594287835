.prg-subscription-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(1, 1, 1, 0.7);
  z-index: 9999999;
}

.prg-subscription-modal-container {
  background-color: #fff;
  border-radius: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60vw;
  max-height: 90vh;
  transform: translate(-50%, -50%);
  overflow: hidden;
  overflow-y: auto;
}

.prg-subscription-modal-container.wide {
  width: 80vw;
}

.prg-subscription-result {
  padding: var(--doublePadding);
  background-color: var(--brandPrimaryColor);
  color: #fff;
  border-radius: 0;
}

.prg-subscription-result h3 {
  color: #fff;
}

.prg-subscription-result button {
  margin: 0 0 0 auto;
  display: block;
}

.prg-subscription-result p {
  max-width: 60%;
  margin: var(--standardPadding) 0;
}

.prg-existing-login {
  margin: var(--standardPadding) auto;
  max-width: 60%;
  text-align: center;
}

.prg-existing-login button {
  font-weight: bold;
  text-decoration: underline;
}

.prg-subscription-result input {
  border: 1px solid #fff;
  padding: var(--quarterPadding) var(--halfPadding);
  border-radius: 3px;
  display: block;
  margin: var(--standardPadding) auto;
  width: 50%;
  outline: none;
}

.prg-subscription-result input:focus {
  border: 1px solid #fff !important;
}

.prg-sub-table {
  display: flex;
  flex-direction: column;
  gap: var(--halfPadding);
}

.prg-row {
  display: flex;
  border-bottom: 1px solid #fff;
  gap: 0.5rem;
}

.prg-row.header-row {
  font-weight: bold;
  border: none;
}

.prg-col {
  flex: initial;
  width: 28%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.prg-col.half {
  width: 12%;
}

.redemption-status {
  border: 1px solid #fff;
  border-radius: 4px;
  height: 1rem;
  width: 1rem;
  display: block;
}

.redemption-status.redeemed {
  background-color: var(--brandSecondaryColor);
  border-color: var(--brandSecondaryColor);
}

/* Mobile */
@media screen and (max-width: 860px) {
  .prg-subscription-modal-container {
    width: 92vw;
    max-height: 90vh;
  }

  .prg-subscription-modal-container.wide {
    width: 92vw;
  }

  .prg-subscription-result input {
    width: 80%;
  }

  .prg-row {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 0 1rem;
  }

  .prg-row.header-row .prg-col.half {
    display: none;
  }

  .prg-row.header-row {
    display: flex;
    flex-direction: row;
    padding: 0;
  }

  .prg-col {
    width: 100%;
  }

  .prg-col.half {
    width: 100%;
  }
}
