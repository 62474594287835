.prg-toast {
  position: fixed;
  top: var(--standardPadding);
  left: 50%;
  transform: translateX(-50%) translateY(2rem) scaleY(0.4);
  transform-origin: 50% 100%;
  z-index: 9999999;
  padding: var(--quarterPadding) var(--standardPadding);
  display: block;
  background-color: var(--pageColor);
  transition: all 0.4s ease;
  opacity: 0;
  font-size: var(--prgBody);
}

.prg-toast.success {
  background-color: var(--greenColor);
}

.prg-toast.error {
  background-color: var(--redColor);
  color: #fff;
}

.prg-toast.show {
  opacity: 1;
  transform: translateX(-50%);
}

/* Mobile */
@media screen and (max-width: 860px) {
  .prg-toast {
    width: calc(100% - 2 * var(--doublePadding));
  }
}
