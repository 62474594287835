.prg-hero-banner-container {
  overflow: hidden;
  margin: 0;
  width: 100%;
  height: 0;
  padding-bottom: 37%;
  overflow: hidden;
  position: relative;
  background-size: 800%;
  background-position: 50% 50%;
  background-image: linear-gradient(to right, #fafafa 0%, #ccc 50%);
  animation: sheen 3s infinite ease forwards;
}

.prg-hero-banner-container.use-natural-ratio {
  height: auto;
  padding-bottom: 0;
}

.prg-hero-banner-container.actionable:hover {
  cursor: pointer;
  opacity: 0.95;
}

.prg-hero-banner-lightbox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(1, 1, 1);
  z-index: 1;
}

.prg-hero-banner-image {
  background-repeat: no-repeat;
  background-position: 50% 0%;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.prg-hero-banner-container.use-natural-ratio img {
  width: 100%;
  display: block;
}

.prg-hero-banner-container .svg-container {
  left: 36.5%;
  width: 27%;
  top: 14%;
  position: absolute;
  z-index: 2;
}

.prg-hero-banner-container svg {
  width: 100%;
  height: auto;
}

.prg-hero-banner-container .button-container {
  position: absolute;
  bottom: 6%;
  left: 50%;
  transform: translateX(-100%);
  z-index: 2;
  display: flex;
  gap: var(--doublePadding);
  flex-direction: row-reverse;
}

.prg-hero-banner-container .button-container button.use-brand-color {
  color: var(--brandPrimaryColor);
}

.prg-hero-banner-container .svg-container.style-original {
  width: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0.9);
}

@keyframes sheen {
  0% {
    background-position: 10% 50%;
  }
  50% {
    background-position: 20% 20%;
  }
  100% {
    background-position: 0% 10%;
  }
}

/* Mobile */
@media screen and (max-width: 860px) {
  .prg-hero-banner-container {
    padding-bottom: 70%;
  }

  .prg-hero-banner-container .svg-container {
    width: 40%;
    left: 30%;
  }
}

@media screen and (max-width: 860px) and (orientation: landscape) {
  .prg-hero-banner-container {
    padding: 0 !important;
    height: calc(90vh - var(--headerHeight));
  }

  .prg-hero-banner-container .svg-container {
    width: 30% !important;
    left: 35% !important;
  }
}
