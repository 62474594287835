.prg-block-container {
  margin: var(--quadPadding) 0;
  padding: 0 var(--doublePadding);
}

.prg-block-container.with-background {
  background-color: var(--brandPrimaryColor);
}

.prg-block-container.with-background .prg-text-hero-container h2 {
  color: var(--brandSecondaryColor);
}

.prg-block-container.with-background .prg-block-menu li button {
  color: var(--brandSecondaryColor);
}

.prg-block-container.with-background .prg-block-menu li button.selected {
  text-decoration-color: var(--brandSecondaryColor);
}

.prg-block-container-content {
  margin: 0 auto;
  max-width: 1200px;
}

.prg-block-container .prg-text-hero {
  padding: var(--quadPadding) var(--doublePadding) 0;
}

.prg-block-container-content.flex {
  display: flex;
  gap: var(--doublePadding);
}

.prg-block-container-content.full-width .prg-block-container {
  margin: 0;
  padding: 0;
}

.prg-block-container-content.full-width .prg-scroller-content {
  padding: var(--doublePadding) 0 0;
  margin: var(--standardPadding) 0 0;
}

.prg-block-container-content.full-width .prg-scroller-content-container h1 {
  margin: 0;
}

.prg-block-container-content.full-width
  .prg-scroller-content
  .prg-scroller-content-items {
  width: 100%;
}

.prg-block-container-content.full-width
  .prg-scroller-content
  .prg-scroller-content-items
  .prg-scroller-content-item-image-copy
  p {
  margin: 0 auto;
  text-align: center;
  width: 40%;
}

.prg-block-item {
  flex: initial;
}

.prg-block-item:only-of-type {
  margin: 0 auto;
}

.prg-block-menu-container {
  padding: 0 var(--doublePadding);
}

.prg-block-menu {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 var(--quadPadding);
  display: flex;
  flex-wrap: wrap;
  gap: var(--standardPadding);
  justify-content: center;
}

.prg-block-menu li button {
  text-transform: uppercase;
}

.prg-block-menu li button.selected {
  text-decoration: underline;
  text-decoration-color: var(--brandPrimaryColor);
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
  pointer-events: none;
}

/* Mobile */
@media screen and (max-width: 860px) {
  .prg-block-container {
    margin: var(--doublePadding) 0;
    padding: 0;
  }
}

@media screen and (max-width: 860px) and (orientation: portrait) {
  .prg-block-container-content.flex {
    flex-direction: column;
  }

  .prg-block-item {
    width: 100% !important;
  }

  .prg-block-menu-container {
    overflow: hidden;
    padding: 0;
    width: calc(100% - 2 * var(--doublePadding));
    margin: 0 auto;
  }

  .prg-block-menu {
    padding: var(--doublePadding) 0;
    flex-wrap: nowrap;
    overflow-x: scroll;
    display: inline-flex;
    max-width: 100%;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 860px) and (orientation: landscape) {
  .prg-block-container-content.full-width
    .prg-scroller-content
    .prg-scroller-content-item {
    width: 100% !important;
  }
}
