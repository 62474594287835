.prg-header {
  background-color: var(--brandPrimaryColor);
  height: var(--headerHeight);
  padding: 0 var(--doublePadding);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 9999;
}

.prg-header.with-alert {
  height: calc(var(--headerHeight) + 3rem);
}

.prg-header-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: var(--headerHeight);
  position: relative;
  z-index: 9999;
}

.prg-header-nav-btn {
  height: 30%;
}

.prg-header-nav-btn svg {
  height: 100%;
  width: auto;
}

.prg-header-logo-btn {
  height: var(--headerHeight);
  width: 4.5rem;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.4s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prg-header-logo-btn div {
  height: 100%;
}

.prg-header-logo-btn svg {
  height: 85%;
  width: 85%;
  display: block;
}

.prg-header-logo-btn:hover {
  transform: translate(-50%, -54%);
}

.prg-header-right {
  display: flex;
  gap: var(--standardPadding);
  margin: 0 0 0 auto;
  align-items: center;
}

.prg-location-btn {
  color: var(--brandSecondaryColor);
  font-size: var(--prgH3);
  font-family: var(--secondaryFont);
}

.prg-header-order-btn.prg-primary-btn {
  background-color: var(--brandSecondaryColor);
  color: var(--brandPrimaryColor);
}

.prg-cart-btn {
  position: relative;
}

.prg-cart-btn span {
  display: inline-block;
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  background-color: var(--redColor);
  color: #fff;
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 100%;
  text-align: center;
  font-size: 0.8rem;
  line-height: 1.2rem;
  font-family: var(--primaryFont);
}

.prg-cart-btn svg {
  height: 2rem;
  width: auto;
}

.prg-cart-btn svg path {
  fill: var(--brandPrimaryColor);
}

.prg-header-alert {
  background-color: var(--brandSecondaryColor);
  color: var(--pageColor);
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% + 2 * var(--doublePadding));
  margin: 0 0 0 calc(-1 * var(--doublePadding));
  font-weight: 600;
}

.prg-header-alert a {
  display: inline-block;
  margin: 0 4px;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 3px;
}

.prg-header-alert a:hover {
  opacity: 0.8;
}

/* Mobile */
@media screen and (max-width: 860px) {
  .prg-header-reservation-btn.prg-main-reservation-btn.prg-primary-btn,
  .prg-header-order-btn.prg-main-order-btn.prg-primary-btn {
    display: none;
  }

  .prg-header-logo-btn {
    height: 1.75rem;
  }

  .prg-location-btn {
    font-size: var(--prgH5);
  }

  .prg-cart-btn span {
    top: -0.3rem;
    right: -0.3rem;
    height: 1rem;
    width: 1rem;
    font-size: 0.7rem;
    line-height: 1rem;
  }

  .prg-cart-btn svg {
    height: 1.5rem;
  }

  .prg-header-alert {
    display: block;
    padding: var(--quarterPadding);
    text-align: center;
    font-size: 0.75rem;
  }
}

/* Mobile landscape */
@media screen and (max-width: 860px) and (orientation: landscape) {
  .prg-header {
    padding: 0 var(--quadPadding);
  }

  .prg-header-alert {
    width: 100vw;
    display: flex;
    margin: 0 0 0 calc(-1 * var(--quadPadding));
  }
}
