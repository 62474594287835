.prg-cta-block {
  border: 7px solid var(--brandPrimaryColor);
  padding: var(--doublePadding);
  text-align: center;
  margin: var(--doublePadding) 0;
}

.prg-cta-block.illustration,
.prg-cta-block.illustrationWithBorder,
.prg-cta-block.patterned,
.prg-cta-block.secondary,
.prg-cta-block.banner {
  border: none;
}

.prg-cta-block.illustrationWithBorder,
.prg-cta-block.illustration,
.prg-cta-block.patterned,
.prg-cta-block.banner {
  padding: 0;
  position: relative;
}

.prg-cta-block.banner {
  margin: 0 0 var(--doublePadding);
}

.prg-cta-block-wrapper {
  background-repeat: no-repeat;
  height: 38rem;
  background-size: cover;
  position: relative;
}

.prg-cta-block-wrapper-content {
  background-color: var(--brandSecondaryColor);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30%;
  transform: translate(-50%, -50%);
  padding: var(--quadPadding) var(--standardPadding);
}

.prg-cta-block.illustrationWithBorder .prg-cta-block-wrapper-content,
.prg-cta-block.illustration .prg-cta-block-wrapper-content {
  background-color: transparent;
}

.prg-cta-block-wrapper-lightbox {
  background-color: var(--lightboxColor);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
}

.prg-cta-block-wrapper-svg {
  position: absolute;
  top: 0;
  left: calc(50% - 15vw);
  z-index: -1;
  width: 30vw;
}

.prg-cta-block.illustrationWithBorder .prg-cta-block-wrapper-svg {
  position: static;
  width: auto;
  height: 10rem;
  margin: var(--doublePadding) 0 0;
}

.prg-cta-block.illustrationWithBorder .prg-cta-block-wrapper-svg svg {
  width: auto;
  height: 100%;
}

.prg-cta-block.illustrationWithBorder .prg-cta-block-wrapper-content p,
.prg-cta-block.illustrationWithBorder .prg-cta-block-wrapper-content h2 {
  width: 100%;
}

.prg-cta-block-wrapper-svg svg {
  width: 100%;
  height: auto;
}

.prg-cta-block-wrapper-svg-border {
  position: absolute;
  top: 0;
  z-index: -1;
  width: 140%;
  height: 100%;
  left: -20%;
}

.prg-cta-block-wrapper-svg-border svg {
  width: 100%;
  height: 100%;
}

.prg-cta-block h2 {
  font-size: clamp(7.5rem, 7vw + 1rem, 7.75rem);
  font-family: var(--promoFont);
  -webkit-text-stroke: 4px;
  -webkit-text-stroke-color: var(--brandPrimaryColor);
  color: #fff;
  margin: 0 auto;
  width: 60%;
}

.prg-cta-block.secondary h2 {
  font-size: clamp(4.375rem, 3.5vw + 1rem, 4.625rem);
  -webkit-text-stroke: 0;
  font-family: var(--primaryFont);
  text-transform: uppercase;
  font-weight: 400;
  color: var(--brandPrimaryColor);
}

.prg-cta-block-wrapper-content h2 {
  font-size: var(--prgH3);
  font-family: var(--secondaryFontSemiBold);
  font-weight: 500;
  text-transform: uppercase;
  -webkit-text-stroke: 0;
  color: var(--brandTertiaryColor);
}

.prg-cta-block.secondary h2 em {
  font-family: var(--secondaryFont);
  text-transform: capitalize;
}

.prg-cta-block img {
  display: block;
  margin: -2rem auto 0;
  max-width: 100%;
}

.prg-cta-block p {
  width: 22%;
  margin: var(--doublePadding) auto;
  font-size: var(--prgSubhead);
  font-weight: 600;
}

.prg-cta-block-wrapper-content p {
  width: 70%;
  color: #fff;
}

.prg-cta-block p em {
  font-weight: 300;
  color: var(--brandPrimaryColor);
  font-family: var(--secondaryFont);
  font-size: var(--prgBody);
}

.prg-cta-block .prg-primary-btn {
  border: 3px solid var(--brandPrimaryColor);
}

.prg-cta-block .prg-primary-btn.outline {
  background-color: transparent;
  color: var(--brandPrimaryColor);
  border: 3px solid var(--brandPrimaryColor);
}

.prg-cta-block-btns {
  display: flex;
  gap: var(--standardPadding);
  align-items: center;
  justify-content: center;
  margin: var(--quarterPadding) 0;
}

.prg-cta-block .spacer {
  display: inline-block;
  margin: var(--standardPadding) 0;
}

.prg-cta-block-banner {
  display: flex;
  height: 34.5rem;
  margin: -3px 0 0;
}

.prg-cta-block-banner img {
  width: 42%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  margin: 0;
  flex: initial;
}

.prg-cta-block-banner-meta {
  width: 58%;
  flex: initial;
  border: 7px solid var(--brandPrimaryColor);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.prg-cta-block-banner-meta svg {
  height: 10rem;
  width: auto;
  margin: 0 0 var(--doublePadding);
}

.prg-cta-block-banner-meta svg path {
  fill: var(--brandPrimaryColor);
}

.prg-cta-block-banner-meta h2 {
  font-family: var(--primaryFont);
  -webkit-text-stroke: 0;
  color: var(--textColor);
  font-size: var(--prgH3);
}

.prg-cta-block-banner-meta p {
  font-family: var(--secondaryFont);
  color: var(--brandPrimaryColor);
  font-size: var(--prgH3);
  font-weight: 200;
  margin: 0 auto;
  width: 80%;
}

@media screen and (max-width: 860px) {
  .prg-cta-block:not(.banner) h2 {
    width: 100%;
    -webkit-text-stroke: 1px;
    -webkit-text-stroke-color: var(--brandPrimaryColor);
    font-size: clamp(5.5rem, 7vw + 1rem, 5.75rem);
  }

  .prg-cta-block.secondary h2 {
    font-size: clamp(3rem, 2.5vw + 1rem, 3.1rem);
  }

  .prg-cta-block p {
    width: 100%;
  }

  .prg-cta-block-btns {
    flex-direction: column;
  }

  .prg-cta-block-wrapper {
    height: 30rem;
  }

  .prg-cta-block-wrapper-content {
    width: calc(100% - 2 * var(--doublePadding));
    padding: var(--doublePadding);
  }

  .prg-cta-block-wrapper-content h2 {
    font-size: var(--prgH2);
    -webkit-text-stroke: 0;
  }

  .prg-cta-block-wrapper-content p {
    font-size: var(--prgBody);
  }

  .prg-cta-block-banner-meta svg {
    height: auto;
    margin: 0 auto var(--doublePadding);
  }

  .prg-cta-block-banner-meta p {
    margin: 0 auto var(--quarterPadding);
  }

  .prg-cta-block-wrapper-svg-border {
    width: 100%;
    left: 0;
  }
}

@media screen and (max-width: 860px) and (orientation: landscape) {
  .prg-cta-block img {
    max-width: 50%;
  }

  .prg-cta-block:not(.banner) h2 {
    font-size: clamp(4.5rem, 6vw + 1rem, 4.75rem);
  }

  .prg-cta-block-wrapper-content {
    width: calc(100% - 2 * var(--quadPadding));
    padding: calc(2 * var(--doublePadding));
  }

  .prg-cta-block-wrapper-content h2 {
    font-size: var(--prgH2);
  }

  .prg-cta-block-banner {
    height: 80vh;
  }

  .prg-cta-block-banner-meta svg {
    width: 40%;
  }
}

@media screen and (max-width: 860px) and (orientation: portrait) {
  .prg-cta-block-banner {
    flex-direction: column;
    height: auto;
  }

  .prg-cta-block-banner img {
    width: 100%;
    height: 20rem;
  }

  .prg-cta-block-banner-meta {
    width: 100%;
    padding: var(--doublePadding);
  }

  .prg-cta-block-banner-meta svg {
    width: 60%;
  }
}
