.prg-location-map-image-block {
  margin: var(--doublePadding) 0;
}

.prg-location-map-image-block img {
  display: block;
  width: 100%;
}

.prg-location-map-image-block #prg-location-map-display {
  width: 100%;
  height: 50vh;
  background-color: var(--pageColor);
}

.prg-location-map-image-block-container {
  max-width: 1200px;
  margin: 0 auto;
}

.prg-map-marker {
  height: 60px;
  width: 60px;
  background-color: var(--brandSecondaryColor);
  position: relative;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prg-map-marker img {
  width: 80%;
  height: auto;
}

.prg-map-marker-popup {
  position: absolute;
  top: 0;
  left: 50%;
  width: 200px;
  background-color: var(--brandSecondaryColor);
  transform: translate(-50%, -110%);
  padding: var(--halfPadding);
  border-radius: 10px;
  color: var(--brandTertiaryColor);
  text-align: center;
}

.prg-map-marker-popup p {
  font-size: 1rem;
}

@media screen and (max-width: 1200px) {
  .prg-location-map-image-block-container {
    padding: 0 var(--doublePadding);
  }
}

@media screen and (max-width: 860px) and (orientation: landscape) {
  .prg-location-map-image-block #prg-location-map-display {
    height: 100vh;
  }
}
