.prg-location-header-container {
  width: 100vw;
  height: calc(100vh - var(--headerHeight));
  position: absolute;
  top: var(--headerHeight);
  left: 0;
  z-index: 99999;
  background-color: rgba(1, 1, 1, 0.5);
}

.prg-location-header-content-wrapper {
  background-color: var(--brandSecondaryColor);
  padding: var(--doublePadding);
}

.prg-location-header-content {
  display: flex;
  flex-direction: column;
  background-color: var(--brandSecondaryColor);
  max-width: 1200px;
  margin: 0 auto;
}

.prg-location-header-content h3 {
  color: var(--brandPrimaryColor);
}

.prg-location-selector-list {
  display: grid;
  column-gap: var(--standardPadding);
  row-gap: var(--standardPadding);
  margin: var(--standardPadding) 0;
  grid-template-columns: repeat(5, 1fr);
}

.prg-location-selector-list li {
  display: flex;
  gap: var(--halfPadding);
  flex-direction: column;
  border: 3px solid var(--brandPrimaryColor);
  border-radius: 10px;
  padding: var(--halfPadding);
  color: var(--brandPrimaryColor);
  transition: all 0.2s ease;
}

.prg-location-selector-list li h6,
.prg-location-selector-list li p {
  margin: 0;
  color: inherit;
}

.prg-location-selector-list li p {
  font-size: 0.875rem;
}

.prg-location-selector-list li.selected {
  background-color: var(--brandPrimaryColor);
  color: var(--brandSecondaryColor);
}

.prg-location-selector-list li:not(.selected):hover {
  cursor: pointer;
  background-color: var(--brandPrimaryColor);
  color: var(--brandSecondaryColor);
}

@media screen and (max-width: 860px) {
  .prg-location-selector-list {
    grid-template-columns: repeat(2, 1fr);
    column-gap: var(--halfPadding);
    row-gap: var(--halfPadding);
  }

  .prg-location-selector-list li {
    padding: var(--quarterPadding);
  }
}

@media screen and (max-width: 860px) and (orientation: landscape) {
  .prg-location-selector-list {
    margin: var(--halfPadding) 0;
    grid-template-columns: repeat(5, 1fr);
  }
}
