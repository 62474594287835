.prg-three-piece-content {
  margin: var(--quadPadding) 0;
  padding: 0 var(--doublePadding);
}

.prg-three-piece-content-container {
  margin: 0 auto;
  max-width: 1200px;
}

.prg-three-piece-heading-svg {
  width: 100%;
  margin: 0 auto;
}

.prg-three-piece-heading-svg svg {
  width: 100%;
  height: auto;
}

.prg-three-piece-content-flex {
  display: flex;
  align-items: center;
  margin: var(--doublePadding) 0;
  gap: var(--quadPadding);
}

.prg-three-piece-content-flex-subheading {
  flex: initial;
  width: 50%;
  padding: var(--quarterPadding) 0 var(--quadPadding) var(--doublePadding);
  position: relative;
}

.prg-three-piece-content-flex-copy {
  flex: initial;
  width: 50%;
}

.prg-three-piece-content-flex-subheading h2 {
  color: var(--brandPrimaryColor);
  text-transform: uppercase;
  font-weight: 400;
  padding: 0 50% 0 0;
  line-height: var(--prgH2);
}

.prg-three-piece-content-flex-subheading-svg {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

.prg-three-piece-content-flex-subheading-svg svg {
  height: 100%;
  width: auto;
}

/* Mobile */
@media screen and (max-width: 860px) {
  .prg-three-piece-content-flex {
    flex-direction: column;
  }

  .prg-three-piece-content-flex-subheading {
    width: 100%;
    padding: 0;
  }

  .prg-three-piece-content-flex-subheading h2 {
    font-size: var(--prgH3);
    line-height: var(--prgH3);
  }

  .prg-three-piece-content-flex-copy {
    width: 100%;
  }
}

/* Mobile - landscape */
@media screen and (max-width: 860px) and (orientation: landscape) {
  .prg-three-piece-content-flex-subheading-svg {
    height: 200%;
  }
}
