.prg-people-list {
  margin: var(--quadPadding) 0;
  padding: 0 var(--doublePadding);
}

.prg-people-list-container {
  margin: 0 auto;
  max-width: 1200px;
}

.prg-people-list-container h1 {
  color: var(--brandPrimaryColor);
  text-align: center;
  font-size: 6rem;
  font-family: var(--secondaryFont);
}

.prg-people-list-container h4 {
  color: var(--brandPrimaryColor);
  font-size: 1.5rem;
  font-family: var(--promoFont);
  text-align: center;
  margin: -1rem 0 0 -37%;
}

.prg-people-list-items {
  margin: var(--doublePadding) 0;
  display: flex;
  flex-direction: column;
  gap: var(--doublePadding);
}

.prg-people-list-item {
  display: flex;
  align-items: center;
  padding: 0 0 var(--standardPadding);
}

.prg-people-list-item.actionable:hover {
  cursor: pointer;
  opacity: 0.6;
}

.prg-people-list-item:not(:last-child) {
  border-bottom: 3px solid rgba(0, 0, 0, 0.3);
}

.prg-people-list-item h5,
.prg-people-list-item h6 {
  margin: 0;
  padding: 0;
  font-size: var(--prgBody);
  font-weight: 400;
}

.prg-people-list-item h5 {
  text-decoration: underline;
  text-decoration-color: var(--brandPrimaryColor);
  text-decoration-thickness: 3px;
  text-underline-offset: 5px;
}

.prg-people-list-item h6 {
  margin: 0 0 0 auto;
}

/* Mobile */
@media screen and (max-width: 860px) and (orientation: portrait) {
  .prg-people-list-item {
    flex-direction: column;
    gap: var(--standardPadding);
  }

  .prg-people-list-item h6 {
    margin: 0;
  }
}
