.prg-image-grid {
  margin: var(--doublePadding) 0;
  padding: 0 var(--doublePadding);
}

.prg-image-grid ul {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: var(--doublePadding);
  row-gap: 0;
}

.prg-image-grid ul li.actionable:hover {
  cursor: pointer;
  opacity: 0.9;
}

.prg-image-grid ul li img {
  display: block;
  width: 100%;
}

.prg-image-grid-label {
  font-size: var(--prgBody);
  text-align: center;
  margin: var(--standardPadding) 0 0;
}

.prg-image-grid.masonry ul {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-auto-flow: row;
  grid-template-areas:
    'a b c'
    'a d e';
  row-gap: 0;
  height: 50rem;
  margin: 0 auto var(--quadPadding);
}

.prg-image-grid.masonry ul li:nth-child(1) {
  grid-area: a;
  height: 50rem;
}

.prg-image-grid.masonry ul li:nth-child(2) {
  grid-area: b;
  height: calc(35rem - var(--doublePadding));
  margin: 0 0 var(--doublePadding);
}

.prg-image-grid.masonry ul li:nth-child(3) {
  grid-area: c;
  height: calc(35rem - var(--doublePadding));
  margin: 0 0 var(--doublePadding);
}

.prg-image-grid.masonry ul li:nth-child(4) {
  grid-area: d;
  height: 15rem;
  width: 140%;
}

.prg-image-grid.masonry ul li:nth-child(5) {
  grid-area: e;
  height: 15rem;
  width: 60%;
  margin: 0 0 0 auto;
}

.prg-image-grid.masonry ul li img {
  height: 100%;
  object-fit: cover;
}

/* Mobile landscape */
@media screen and (max-width: 860px) and (orientation: landscape) {
  .prg-image-grid ul {
    grid-template-columns: repeat(3, 1fr);
  }

  .prg-image-grid.masonry ul {
    height: 75vh;
  }

  .prg-image-grid.masonry ul li:nth-child(1) {
    grid-area: a;
    height: 75vh;
  }

  .prg-image-grid.masonry ul li:nth-child(2) {
    grid-area: b;
    height: calc(40vh - var(--doublePadding));
    margin: 0 0 var(--doublePadding);
  }

  .prg-image-grid.masonry ul li:nth-child(3) {
    grid-area: c;
    height: calc(40vh - var(--doublePadding));
    margin: 0 0 var(--doublePadding);
  }

  .prg-image-grid.masonry ul li:nth-child(4) {
    grid-area: d;
    height: 35vh;
    width: 140%;
  }

  .prg-image-grid.masonry ul li:nth-child(5) {
    grid-area: e;
    height: 35vh;
    width: 60%;
    margin: 0 0 0 auto;
  }
}

/* Mobile portrait */
@media screen and (max-width: 860px) and (orientation: portrait) {
  .prg-image-grid.masonry ul {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-auto-flow: row;
    grid-template-areas:
      'a b'
      'c c '
      'd e';
    row-gap: 0;
    height: 70vh;
    margin: 0 auto var(--quadPadding);
  }

  .prg-image-grid.masonry ul li:nth-child(1) {
    grid-area: a;
    height: 30vh;
  }

  .prg-image-grid.masonry ul li:nth-child(2) {
    grid-area: b;
    height: 30vh;
  }

  .prg-image-grid.masonry ul li:nth-child(3) {
    grid-area: c;
    height: 20vh;
  }

  .prg-image-grid.masonry ul li:nth-child(4) {
    grid-area: d;
    height: 15vh;
  }

  .prg-image-grid.masonry ul li:nth-child(5) {
    grid-area: e;
    height: 15vh;
  }
}
