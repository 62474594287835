.prg-people-grid {
  margin: var(--quadPadding) 0;
  padding: 0 var(--doublePadding);
}

.prg-people-grid-container {
  margin: 0 auto;
  max-width: 1200px;
}

.prg-people-grid-container h1 {
  color: var(--brandPrimaryColor);
  text-align: center;
  font-size: 6rem;
  font-family: var(--secondaryFont);
}

.prg-people-grid-container h4 {
  color: var(--brandPrimaryColor);
  font-size: 1.5rem;
  font-family: var(--promoFont);
  text-align: center;
  margin: -1rem 0 0 -37%;
}

.prg-people-grid-items {
  margin: var(--doublePadding) 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: var(--doublePadding);
  row-gap: calc(1.5 * var(--quadPadding));
}

.prg-people-grid-item {
  display: flex;
  gap: var(--halfPadding);
  flex-direction: column;
  position: relative;
}

.prg-people-grid-item.actionable:hover {
  cursor: pointer;
  opacity: 0.9;
}

.prg-people-grid-item.actionable:after {
  content: 'View Bio';
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: var(--brandPrimaryColor);
  color: var(--brandSecondaryColor);
  font-size: 1rem;
  padding: var(--quarterPadding);
  display: none;
}

.prg-people-grid-item.actionable:hover:after {
  display: block;
}

.prg-people-grid-photo {
  height: 32rem;
  background-color: var(--brandPrimaryColor);
  width: 100%;
  margin: 0 0 var(--quarterPadding);
  position: relative;
}

.prg-people-grid-photo img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.prg-people-grid-item h5,
.prg-people-grid-item h6 {
  margin: 0;
  padding: 0;
  font-size: var(--prgBody);
  font-weight: 400;
}

.prg-people-grid-item h5 {
  text-decoration: underline;
  text-decoration-color: var(--brandPrimaryColor);
  text-decoration-thickness: 3px;
  text-underline-offset: 5px;
}

/* Mobile */
@media screen and (max-width: 860px) {
  .prg-people-grid-container h1 {
    font-size: var(--prgH2);
    line-height: var(--prgH2);
  }

  .prg-people-grid-container h4 {
    font-size: 1rem;
    margin: 0 auto;
  }

  .prg-people-grid-items {
    grid-template-columns: repeat(2, 1fr);
    row-gap: calc(1.5 * var(--doublePadding));
  }

  .prg-people-grid-photo {
    height: 10rem;
  }
}

/* Mobile - landscape */
@media screen and (max-width: 860px) and (orientation: landscape) {
  .prg-people-grid-items {
    grid-template-columns: repeat(4, 1fr);
  }
}
