.prg-quote-block {
  margin: var(--quadPadding) 0;
  padding: 0 var(--doublePadding);
}

.prg-quote-block-container {
  margin: 0 auto;
  max-width: 1200px;
  text-align: center;
}

.prg-quote-block-container h1 {
  position: relative;
  font-family: var(--primaryFontLight);
  font-weight: 300;
  font-size: 2rem;
  margin: 0 0 var(--quadPadding);
}

.prg-quote-block-container h1:before {
  content: '“';
  position: absolute;
  text-transform: uppercase;
  color: var(--brandPrimaryColor);
  top: -4rem;
  left: 0;
  font-family: var(--secondaryFont);
  font-size: var(--prgH1);
  font-weight: 600;
}

.prg-quote-block-container h1:after {
  content: '”';
  position: absolute;
  color: var(--brandPrimaryColor);
  bottom: -6rem;
  right: 0;
  font-family: var(--secondaryFont);
  font-size: var(--prgH1);
  font-weight: 600;
}

.prg-quote-block-container h5 {
  color: var(--brandPrimaryColor);
  font-size: var(--prgH3);
  font-family: var(--secondaryFont);
  font-weight: 700;
}

.prg-quote-block-container h6 {
  color: var(--brandPrimaryColor);
  font-size: 1.5rem;
  font-family: var(--promoFont);
  font-weight: 600;
}

/* Mobile */
@media screen and (max-width: 860px) {
  .prg-quote-block-container h1 {
    font-size: 1.5rem;
  }

  .prg-quote-block-container h1:before {
    top: -4rem;
  }

  .prg-quote-block-container h1:after {
    bottom: -7rem;
  }

  .prg-quote-block-container h6 {
    font-size: 1.25rem;
  }
}

/* Mobile landscape */
@media screen and (max-width: 860px) and (orientation: landscape) {
  .prg-quote-block-container h1 {
    font-size: 1.3rem;
  }

  .prg-quote-block-container h1:before {
    top: -4rem;
  }

  .prg-quote-block-container h1:after {
    bottom: -7rem;
  }

  .prg-quote-block-container h6 {
    font-size: 1.1rem;
  }
}
