.prg-cart-page {
  margin: 0 0 var(--quadPadding);
  padding: 0 var(--doublePadding);
}

.prg-cart-page-content {
  margin: 0 auto;
  max-width: 1200px;
}

.prg-cart-page-content h5,
.prg-cart-page-content h2,
.prg-cart-page-content .prg-subtotal,
.prg-cart-page-content .prg-primary-btn {
  transform: translateY(20px);
  opacity: 0;
}

.prg-cart-items {
  margin: var(--doublePadding) 0 var(--standardPadding);
}

.prg-cart-item {
  display: flex;
  gap: var(--doublePadding);
  border-bottom: 1px solid var(--grayColor);
  padding: var(--standardPadding) 0;
  transform: scale(0.98) translateY(50px);
  opacity: 0;
  position: relative;
}

.prg-cart-image {
  width: 12rem;
  height: 10rem;
  position: relative;
  flex-shrink: 0;
}

.prg-cart-image:hover {
  cursor: pointer;
}

.prg-cart-image:hover img {
  box-shadow: 3px 4px 2px rgba(1, 1, 1, 0.2);
}

.prg-cart-image img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: 50% 50%;
  transition: all 0.3s ease;
}

.prg-in-stock {
  color: var(--brandPrimaryColor);
}

.prg-no-stock {
  color: var(--redColor);
}

.prg-cart-meta {
  flex: initial;
  display: flex;
  gap: var(--quarterPadding);
  flex-direction: column;
  align-items: flex-start;
}

.prg-cart-meta .prg-dropdown-component {
  width: 10rem;
}

.prg-cart-meta h4 {
  font-family: var(--secondaryFontSemiBold);
  font-weight: 500;
  text-transform: uppercase;
}

.prg-cart-price {
  flex: initial;
  margin: 0 0 0 auto;
}

.prg-cart-price h4 {
  font-family: var(--secondaryFont);
  font-weight: 400;
}

.prg-cart-container .prg-subtotal {
  font-family: var(--secondaryFontSemiBold);
  font-weight: 500;
  text-transform: uppercase;
  display: flex;
  gap: var(--quarterPadding);
  align-items: center;
  justify-content: flex-end;
}

.prg-cart-container .prg-subtotal span {
  display: inline-block;
}

.prg-cart-container .prg-subtotal.loading span {
  content: '';
  width: 2rem;
  height: 0.1rem;
  background-color: var(--textColor);
}

.prg-cart-container .prg-primary-btn {
  margin: var(--standardPadding) 0 0 auto;
  display: block;
}

.prg-cart-container .prg-cart-delete-btn {
  color: var(--redColor);
  margin: auto 0 0 0;
  font-size: 90%;
}

.prg-cart-no-items {
  margin: var(--doublePadding) 0;
}

.prg-cart-no-items a {
  margin: var(--standardPadding) 0 0;
  display: inline-block;
}

@media screen and (max-width: 860px) and (orientation: portrait) {
  .prg-cart-image {
    width: 5rem;
    height: 5rem;
  }

  .prg-cart-container li .prg-cart-price {
    margin: 0;
    white-space: nowrap;
  }

  .prg-cart-meta h4,
  .prg-cart-meta h6 {
    margin: 0;
  }

  .prg-cart-container .prg-subtotal {
    text-align: center;
    justify-content: center;
  }

  .prg-cart-container .prg-primary-btn {
    margin: var(--standardPadding) auto;
  }
}
