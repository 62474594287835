.prg-scroller-content {
  margin: var(--quadPadding) 0;
  padding: 0 var(--doublePadding);
}

.prg-scroller-content.style-primary {
  background-color: var(--brandPrimaryColor);
  padding: calc(1.5 * var(--doublePadding)) var(--doublePadding);
  color: var(--brandSecondaryColor);
  margin: var(--quadPadding) 0 0;
}

.prg-scroller-content-container {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 0 var(--doublePadding);
}

.prg-scroller-content-container h1 {
  text-align: center;
  margin: 0 0 var(--quadPadding);
  text-transform: uppercase;
  font-family: var(--secondaryFont);
}

.prg-scroller-content.style-secondary .prg-scroller-content-container h1 {
  color: var(--brandPrimaryColor);
}

.prg-scroller-content-container h2 {
  margin: 0;
  text-align: right;
  font-size: 1.5rem;
  font-family: var(--promoFont);
  transform: rotate(-8deg) translate(-20%, -170%);
}

.prg-scroller-content-items {
  display: inline-flex;
  gap: var(--quadPadding);
  padding: 0 0 var(--quadPadding);
  overflow-x: auto;
}

.prg-scroller-content-items::-webkit-scrollbar {
  height: 0.625rem;
}

.prg-scroller-content.style-primary
  .prg-scroller-content-items::-webkit-scrollbar-track {
  background-color: #838383;
}

.prg-scroller-content-items::-webkit-scrollbar-track {
  height: 0.375rem;
  background-clip: content-box;
  background: #f2f2f2;
  border: 2px solid transparent;
  background-clip: content-box;
}

.prg-scroller-content.style-primary
  .prg-scroller-content-items::-webkit-scrollbar-thumb {
  background-color: var(--brandSecondaryColor);
}

.prg-scroller-content-items::-webkit-scrollbar-thumb {
  background-color: var(--brandPrimaryColor);
  height: 0.625rem;
  height: 100%;
}

.prg-scroller-content-item {
  flex-shrink: 0;
}

.prg-scroller-content-item-meta {
  display: flex;
  flex-direction: column;
  gap: var(--standardPadding);
}

.prg-scroller-content.style-primary .prg-scroller-content-item-meta {
  display: block;
}

.prg-scroller-content.style-primary .prg-scroller-content-item-image-copy {
  display: flex;
  gap: calc(1.25 * var(--quadPadding));
  margin: var(--doublePadding) 0 0;
  position: relative;
}

.prg-scroller-content.style-primary .prg-scroller-content-item-svg {
  position: absolute;
  top: 0;
  right: 50%;
  height: 10.2rem;
  transform: translate(15%, -50%);
}

.prg-scroller-content.style-primary .prg-scroller-content-item-svg svg {
  height: 100%;
  width: auto;
}

.prg-scroller-content.style-primary .prg-scroller-content-item-image-copy p {
  flex: initial;
  width: 50%;
}

.prg-scroller-content.style-primary .prg-scroller-content-item-image-copy img {
  flex: initial;
  width: 50%;
  height: 33rem;
  object-fit: cover;
}

.prg-scroller-content.style-primary .prg-scroller-content-item h3 {
  color: var(--brandSecondaryColor);
  font-size: var(--prgH1);
  margin: 0;
}

.prg-scroller-content-item h3 {
  color: var(--brandPrimaryColor);
  text-transform: uppercase;
  font-family: var(--secondaryFont);
}

.prg-scroller-content.style-primary .prg-scroller-content-item h4 {
  color: var(--brandSecondaryColor);
  font-size: 2rem;
  margin: -1rem 0 0;
  font-weight: 300;
}

/* Mobile */
@media screen and (max-width: 860px) {
  .prg-scroller-content-item {
    width: 100% !important;
  }

  .prg-scroller-content-container {
    padding: 0 var(--doublePadding);
  }

  .prg-scroller-content-container h1 {
    font-size: var(--prgH2);
    line-height: var(--prgH2);
    margin: 0 0 var(--doublePadding) !important;
  }

  .prg-scroller-content-container h2 {
    font-size: 1rem;
    transform: rotate(-8deg) translate(-20%, -80%);
  }

  .prg-scroller-content.style-primary .prg-scroller-content-item h3 {
    font-size: var(--prgH2);
    line-height: var(--prgH2);
  }

  .prg-scroller-content.style-primary .prg-scroller-content-item h4 {
    font-size: var(--prgH4);
    margin: 0;
  }

  .prg-scroller-content.style-primary .prg-scroller-content-item-image-copy {
    gap: 0;
    margin: var(--doublePadding) 0 0;
  }

  .prg-scroller-content.style-primary .prg-scroller-content-item-image-copy p {
    width: 100%;
  }

  .prg-scroller-content-item-image-copy {
    flex-direction: column;
  }

  .prg-scroller-content-item-svg {
    display: none;
  }

  .prg-scroller-content.style-primary
    .prg-scroller-content-item-image-copy
    img {
    width: 100%;
    height: 20rem;
    margin: 0 0 var(--doublePadding);
  }
}

@media screen and (max-width: 860px) and (orientation: portrait) {
  .prg-scroller-content-items {
    display: flex;
    flex-direction: column;
    padding: 0 0 var(--doublePadding);
    overflow: hidden;
  }
}

@media screen and (max-width: 860px) and (orientation: landscape) {
  .prg-scroller-content-item {
    width: 60% !important;
  }

  .prg-scroller-content-items {
    padding: 0 0 var(--doublePadding);
  }

  .prg-scroller-content-container {
    padding: var(--doublePadding);
  }
}
