.prg-location-selector {
  display: flex;
  max-width: 1200px;
  margin: var(--standardPadding) auto;
  gap: var(--doublePadding);
  align-items: center;
  position: relative;
}

.prg-location-selector-left {
  width: 25%;
  flex: initial;
  display: flex;
  flex-direction: column;
  gap: var(--standardPadding);
  text-align: center;
  padding: var(--doublePadding) var(--doublePadding) var(--doublePadding) 0;
  position: relative;
}

.prg-location-selector-left:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0.5625rem;
  background-color: #000;
  height: 100%;
  width: 0.1875rem;
}

.prg-location-selector-left h4 {
  margin: var(--standardPadding) 0 var(--quarterPadding);
  text-transform: uppercase;
}

.prg-location-selector-left p {
  margin: 0;
}

.prg-location-selector-location {
  margin: var(--doublePadding) 0 0;
}

.prg-location-selector .prg-underline-btn {
  margin: var(--doublePadding) 0 0;
}

.prg-location-selector-right {
  width: 75%;
  flex: initial;
  display: flex;
  padding: 0 10%;
  justify-content: center;
  align-items: center;
}

.prg-location-selector-right svg {
  width: 100%;
  height: auto;
  margin: 0;
  display: block;
}

@media screen and (max-width: 1200px) {
  .prg-location-selector {
    width: calc(100% - 2 * var(--doublePadding));
  }
}

/* Mobile */
@media screen and (max-width: 860px) and (orientation: portrait) {
  .prg-location-selector {
    flex-direction: column;
  }

  .prg-location-selector-left {
    width: 100%;
    padding: var(--doublePadding) var(--doublePadding) var(--quadPadding);
  }

  .prg-location-selector-left:after {
    top: auto;
    bottom: 0;
    right: auto;
    left: 0;
    width: 100%;
    height: 0.1875rem;
  }

  .prg-location-selector-right {
    width: 100%;
  }
}

@media screen and (max-width: 860px) and (orientation: landscape) {
  .prg-location-selector-left {
    width: 50%;
    padding: var(--standardPadding);
    gap: var(--quarterPadding);
  }

  .prg-location-selector-right {
    width: 50%;
    padding: 0;
  }
}
