.prg-catering-info {
  padding: var(--doublePadding);
  margin: var(--doublePadding) 0;
}

.prg-catering-info-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 0 var(--doublePadding);
  gap: var(--doublePadding);
  align-items: center;
}
