.prg-content-line-break {
  border-radius: 0.5625rem;
  background-color: #000;
  width: 100%;
  height: 0.1875rem;
  margin: var(--doublePadding) auto;
  max-width: 1200px;
}

.prg-content-line-break.hide-line {
  background-color: transparent;
}

.prg-content-line-break.extra-top {
  margin: var(--quadPadding) auto var(--doublePadding);
}

.prg-content-line-break.extra-bottom {
  margin: var(--doublePadding) auto var(--quadPadding);
}

.prg-content-line-break.extra-both {
  margin: var(--quadPadding) auto;
}

@media screen and (max-width: 1200px) {
  .prg-content-line-break {
    width: calc(100% - 2 * var(--doublePadding));
  }
}
