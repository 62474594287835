.prg-menu-list {
  margin: var(--doublePadding) 0;
  padding: 0 var(--doublePadding);
}

.prg-menu-list h4 {
  text-align: center;
  font-weight: 500;
  font-size: var(--prgBody);
  margin: calc(1.5 * var(--doublePadding)) 0 var(--quadPadding);
}

.prg-menu-list-container {
  margin: 0 auto;
  max-width: 1200px;
}

.prg-menu-list-container h3 {
  text-align: center;
}

.prg-menu-options {
  display: flex;
  gap: var(--doublePadding);
  margin: var(--standardPadding) 0;
  flex-wrap: wrap;
  justify-content: center;
}

.prg-menu-options li button {
  font-size: var(--prgButton);
  font-weight: 600;
  text-transform: uppercase;
}

.prg-menu-options li button.selected {
  color: var(--brandPrimaryColor);
  pointer-events: none;
  font-weight: bold;
}

.prg-menu-items {
  margin: var(--quadPadding) 0;
  display: flex;
  flex-wrap: wrap;
  gap: var(--doublePadding);
  justify-content: center;
}

.prg-menu-items li {
  flex: initial;
  width: 22%;
}

.prg-menu-item-desc {
  display: flex;
  gap: var(--quarterPadding);
  flex-direction: column;
  text-align: center;
}

.prg-menu-item-icon {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0.5rem 0 0;
}

.prg-menu-item-desc h6 {
  color: var(--brandPrimaryColor);
  font-weight: 600;
  font-size: var(--prgSubhead);
  margin: 0;
  text-transform: uppercase;
}

.prg-menu-item-desc p {
  font-size: var(--prgBody);
  font-weight: 400;
}

.prg-menu-item-extras {
  display: flex;
  flex-direction: column;
  gap: var(--halfPadding);
  align-items: center;
}

.prg-menu-item-extras li {
  color: var(--brandPrimaryColor);
}

/* Mobile */
@media screen and (max-width: 860px) {
  .prg-menu-options li button {
    display: block;
    white-space: initial;
  }

  .prg-menu-items li {
    flex: initial;
    width: 45%;
  }

  .prg-menu-list-container h3 {
    margin: 0 0 calc(1.5 * var(--doublePadding));
  }
}
