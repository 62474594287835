.prg-header-expanded-wrapper {
  position: absolute;
  top: var(--headerHeight);
  left: 0;
  width: 100%;
  height: calc(100vh - var(--headerHeight));
  background-color: rgba(1, 1, 1, 0.4);
}

.prg-header-expanded-wrapper.with-alert {
  top: calc(var(--headerHeight) + 3rem);
  height: calc(100vh - var(--headerHeight) - 3rem);
}

.prg-header-expanded {
  background-color: var(--brandPrimaryColor);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 var(--doublePadding);
  overflow: hidden;
}

.prg-header-expanded-container {
  max-width: 1200px;
  padding: var(--doublePadding) 0;
  display: flex;
  margin: 0 auto;
}

.prg-header-expanded-nav {
  display: flex;
  flex-direction: column;
  gap: var(--doublePadding);
}

.prg-header-expanded-nav li {
  font-size: var(--prgBody);
  color: var(--brandSecondaryColor);
  text-transform: uppercase;
  margin: 0;
  font-weight: 400;
}

.prg-header-expanded a {
  transition: all 0.4s ease;
  display: inline-block;
}

.prg-header-expanded a:hover {
  transform: translateY(-1px);
}

.prg-header-expanded-social-nav {
  margin: auto 0 0 auto;
  display: flex;
  gap: var(--standardPadding);
  align-items: center;
}

.prg-header-expanded-social-nav svg {
  height: 1.5rem;
  width: auto;
}

/* Mobile */
@media screen and (max-width: 860px) {
  .prg-header-expanded {
    height: calc(100vh - var(--headerHeight)) !important;
  }

  .prg-header-expanded-container {
    gap: var(--doublePadding);
  }

  .prg-header-expanded-social-nav {
    margin: 0;
    justify-content: center;
    gap: calc(1.5 * var(--doublePadding));
  }

  .prg-header-expanded-social-nav svg {
    height: 1.2rem;
  }

  .prg-header-expanded-container .prg-header-order-btn {
    background-color: var(--brandSecondaryColor);
    color: var(--brandPrimaryColor);
  }
}

@media screen and (max-width: 860px) and (orientation: portrait) {
  .prg-header-expanded-container {
    flex-direction: column;
  }

  .prg-header-expanded-nav {
    justify-content: center;
    align-items: center;
    gap: calc(1.5 * var(--doublePadding));
  }
}

@media screen and (max-width: 860px) and (orientation: landscape) {
  .prg-header-expanded {
    padding: 0 var(--quadPadding);
  }

  .prg-header-expanded-nav {
    gap: 0.8rem;
  }

  .prg-header-expanded-social-nav {
    margin: auto 0 var(--standardPadding) auto;
  }
}
