.prg-horizontal-list {
  display: flex;
  flex-direction: column;
  gap: var(--standardPadding);
  text-align: center;
}

.prg-horizontal-list h5 {
  color: var(--brandPrimaryColor);
  font-size: var(--prgButton);
  font-weight: 400;
  margin: 0;
  text-transform: uppercase;
}

.prg-horizontal-list ul {
  display: flex;
  gap: calc(2 * var(--doublePadding));
  justify-content: center;
}

.prg-horizontal-list ul li {
  position: relative;
  font-weight: 300;
  font-size: var(--prgBody);
  font-family: var(--primaryFontLight);
  text-transform: uppercase;
}

.prg-horizontal-list ul li:not(:only-child):not(:last-child):after {
  content: '';
  height: 2px;
  background-color: var(--brandPrimaryColor);
  width: 9px;
  position: absolute;
  top: calc(50% + 1px);
  right: calc(-1 * var(--doublePadding) - 4.5px);
}

.prg-horizontal-list ul li h6 {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  white-space: nowrap;
  transform: translate(0.5rem, -102%) rotate(5deg);
  font-family: var(--promoFont);
  font-size: var(--prgH4);
  color: var(--brandPrimaryColor);
  font-weight: 300;
}

/* Mobile */
@media screen and (max-width: 860px) {
  .prg-horizontal-list ul {
    flex-direction: column;
    gap: var(--standardPadding);
  }

  .prg-horizontal-list ul li:not(:only-child):not(:last-child):after {
    display: none;
  }

  .prg-horizontal-list ul li h6 {
    display: none;
  }
}
