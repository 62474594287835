.prg-events-list {
  margin: var(--doublePadding) 0;
  padding: 0 var(--doublePadding);
}

.prg-events-list-container {
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: var(--quadPadding);
}

.prg-events-list-container li {
  text-align: center;
  display: flex;
  gap: var(--standardPadding);
  flex-direction: column;
}

.prg-events-list-container li h2 {
  margin: 0;
  font-weight: 600;
}

.prg-events-list-container li p {
  font-weight: 400;
}

/* Mobile landscape */
@media screen and (max-width: 860px) and (orientation: landscape) {
  .prg-events-list {
    margin: 0;
  }

  .prg-events-list-container {
    gap: var(--standardPadding);
  }
}
