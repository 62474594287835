.prg-page-container {
  padding-top: var(--headerHeight);
  min-height: calc(100vh - 2 * var(--headerHeight));
}

.prg-page-container.sans-header {
  padding-top: 0;
  min-height: calc(100vh - 1 * var(--headerHeight));
}

.prg-page-container.with-alert {
  padding-top: calc(var(--headerHeight) + 3rem);
  min-height: calc(100vh - 2 * (var(--headerHeight) + 3rem));
}
