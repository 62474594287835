.prg-menu-selector-container {
  margin: var(--doublePadding) 0;
  padding: var(--doublePadding) 0;
  text-align: center;
}

.prg-menu-selector {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.prg-menu-selector ul {
  gap: var(--quadPadding);
  margin: var(--doublePadding) 0;
  align-items: center;
  display: inline-grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
}

.prg-menu-selector ul .prg-primary-btn {
  border-color: #000;
}

@media screen and (max-width: 860px) {
  .prg-menu-selector ul {
    gap: var(--doublePadding);
    grid-auto-flow: row;
  }
}
