.prg-dropdown-component {
  border-bottom: 1px solid var(--brandSecondaryColor);
  padding: var(--quarterPadding) 0;
  position: relative;
  z-index: 4444;
}

.prg-dropdown-component button {
  display: flex;
  align-items: center;
  width: 100%;
  color: var(--textColor);
  text-transform: uppercase;
  font-family: var(--secondaryFontSemiBold);
  font-weight: 500;
}

.prg-dropdown-component button svg {
  height: 1.25rem;
  width: auto;
  margin: 0 0 0 auto;
}

.prg-dropdown-component button svg path {
  fill: var(--textColor);
}

.prg-dropdown-component-expanded {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 3px 4px 2px rgba(1, 1, 1, 0.2);
  background-color: #fff;
  padding: var(--quarterPadding);
  display: flex;
  flex-direction: column;
  gap: var(--quarterPadding);
  max-height: 8rem;
  overflow-y: auto;
}

.prg-dropdown-component-expanded li:hover {
  cursor: pointer;
  color: var(--brandPrimaryColor);
}

.prg-dropdown-component-expanded li.disabled {
  opacity: 0.4;
  text-decoration: line-through;
  pointer-events: none;
}

/* Disabled */
.prg-dropdown-component.disabled {
  pointer-events: none;
  border-color: var(--grayColor);
}

.prg-dropdown-component.disabled svg,
.prg-dropdown-component.disabled button {
  pointer-events: none;
  opacity: 0.4;
}
