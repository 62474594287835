.prg-flex-promo {
  margin: var(--doublePadding) 0;
  padding: 0 var(--doublePadding);
}

.prg-flex-promo-heading {
  text-align: center;
  margin: 0 0 var(--doublePadding);
}

.prg-flex-promo-container {
  margin: 0 auto;
  max-width: 1200px;
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  column-gap: var(--quadPadding);
  position: relative;
}

.prg-flex-promo-container.with-dividers:after {
  content: '';
  border-radius: 0.5625rem;
  background-color: #000;
  height: 100%;
  width: 0.1875rem;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}

.prg-flex-promo-item {
  display: flex;
  flex-direction: column;
  gap: var(--standardPadding);
  text-align: center;
  align-items: flex-start;
}

.prg-flex-promo-container.with-dividers .prg-flex-promo-item {
  align-items: center;
}

.prg-flex-promo-container.min-height .prg-flex-promo-item {
  min-height: 15rem;
}

.prg-flex-promo-item svg {
  height: 11rem;
  width: auto;
}

.prg-flex-promo-item svg path.with-stroke {
  stroke-width: 0.35px;
  stroke: #000;
}

.prg-flex-promo-item p {
  padding: 0 15% 0 0;
  text-align: left;
}

.prg-flex-promo-item p.abs {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.prg-flex-promo-item h4 {
  font-family: var(--promoFont);
  color: var(--brandPrimaryColor);
  font-size: clamp(3.438rem, 2.5vw + 1rem, 3.625rem);
  font-weight: normal !important;
  text-transform: uppercase;
  padding: 0 11%;
  margin: auto 0;
}

.prg-flex-promo-addtl-links {
  margin: var(--doublePadding) 0;
  display: flex;
  align-items: center;
  gap: var(--doublePadding);
  justify-content: center;
  text-align: center;
  width: 100%;
}

.prg-flex-promo-svg-divider {
  height: 18rem;
  width: 10rem;
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.prg-flex-promo-svg-divider svg {
  height: 100%;
  width: auto;
}

/* Mobile */
@media screen and (max-width: 860px) {
  .prg-flex-promo-container {
    grid-auto-flow: row;
    row-gap: var(--doublePadding);
  }

  .prg-flex-promo-container.with-dividers {
    row-gap: calc(2 * var(--quadPadding));
  }

  .prg-flex-promo-item img {
    margin: -2rem 0 0;
  }

  .prg-flex-promo-item h4 {
    font-size: clamp(3rem, 2.5vw + 1rem, 3.1rem);
  }

  .prg-flex-promo-addtl-links {
    flex-direction: column;
  }

  .prg-flex-promo-svg {
    max-height: 14rem;
  }
}

@media screen and (max-width: 860px) and (orientation: portrait) {
  .prg-flex-promo-container.with-dividers:after {
    content: '';
    width: 100%;
    height: 0.1875rem;
    position: absolute;
    left: 0;
    top: 50%;
    transform: none;
  }
}

/* Mobile: Landscape */
@media screen and (max-width: 860px) and (orientation: landscape) {
  .prg-flex-promo-container {
    grid-auto-flow: column;
  }

  .prg-flex-promo-container.with-dividers {
    row-gap: calc(1 * var(--quadPadding));
  }

  .prg-flex-promo-svg {
    max-height: 10rem;
  }

  .prg-flex-promo-item img {
    max-height: 20rem;
    margin: 0 0 0 auto !important;
    width: auto !important;
  }
}
