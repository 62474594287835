.prg-careers-list {
  margin: var(--quadPadding) 0;
  padding: 0 var(--doublePadding);
}

.prg-careers-list-content {
  margin: 0 auto;
  max-width: 1200px;
}

.prg-careers-list-content h1 {
  color: var(--brandPrimaryColor);
  font-family: var(--secondaryFont);
  text-align: center;
}

.prg-careers-list-content h6.no-items {
  font-size: 1.5rem;
  text-align: center;
  opacity: 0.5;
  font-weight: 400;
}

.prg-careers-list-filters {
  margin: var(--quadPadding) 0 0;
  padding: 0 0 var(--doublePadding);
  display: flex;
  align-items: center;
  position: relative;
}

.prg-careers-list-filters .prg-primary-btn {
  padding: 0;
}

.prg-careers-list-filters .filter-btn {
  margin: 0 0 0 auto;
}

.prg-careers-list-filters .selected-location-btn {
  display: flex;
  align-items: center;
  gap: var(--halfPadding);
}

.prg-careers-list-filters .selected-location-btn svg {
  height: 0.75rem;
  width: auto;
}

.prg-careers-list-filters .selected-location-btn svg path {
  fill: var(--brandPrimaryColor);
}

.prg-careers-list-location-filters {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--brandPrimaryColor);
  padding: var(--halfPadding);
  display: flex;
  flex-direction: column;
  gap: var(--halfPadding);
  box-shadow: -6px 6px 2px #ccc;
}

.prg-careers-list-location-filters button {
  color: var(--brandSecondaryColor);
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 300;
}

.prg-careers-list-items {
  display: flex;
  flex-direction: column;
  gap: var(--doublePadding);
  margin: var(--quadPadding) 0;
}

.prg-careers-list-item {
  display: flex;
}

.prg-careers-list-item:not(:last-child) {
  border-bottom: 3px solid rgba(0, 0, 0, 0.3);
  padding: 0 0 var(--standardPadding);
}

.prg-careers-list-item h5 {
  margin: 0;
  padding: 0;
  font-size: var(--prgBody);
  font-weight: 300;
}

.prg-careers-list-item a:hover {
  color: var(--brandPrimaryColor);
  text-decoration: underline;
}

.prg-careers-list-item h6 {
  margin: 0 0 0 auto;
  padding: 0;
  font-size: var(--prgBody);
  font-weight: 300;
  color: var(--brandPrimaryColor);
}

.prg-careers-list-item .prg-primary-btn {
  margin: 0;
  padding: 0;
  color: var(--brandPrimaryColor);
}

.prg-careers-list-content .prg-primary-btn {
  color: var(--brandPrimaryColor);
}

/* Mobile */
@media screen and (max-width: 860px) {
  .prg-careers-list {
    margin: 0;
  }

  .prg-careers-list-content h1 {
    font-size: var(--prgH2);
    line-height: var(--prgH2);
  }

  .prg-careers-list-item {
    flex-direction: column;
    text-align: center;
  }

  .prg-careers-list-item h6 {
    margin: 0 auto;
  }
}
